import React from "react";
import "../css/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <h5>© Designed and Created by Stetson Harding</h5>
    </div>
  );
}
export default Footer;
